import { ButtonProps } from '@/twComponents/Button/Button';
import { cn } from '@/utils/cn';

export const ButtonPropsSize = ['sm', 'xs', 'md'] as const;
export type ButtonPropsSizeType = (typeof ButtonPropsSize)[number];

export const hashCode = (str: string, mod: number): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash) % mod;
};

export const getBgColor = (
  s: string,
  colors: string[] = [
    'linear-gradient(45deg, #42a11f, #2dca1f)',
    'linear-gradient(45deg, #73173f, #ca1f68)',
    'linear-gradient(45deg, #99aa38, #3e761a)',
    'linear-gradient(45deg, #25145e, #3910bc)',
    'linear-gradient(45deg, #621e76, #9f1fca)',
    'linear-gradient(45deg, #a92e2e, #ec2727)',
  ],
) => {
  const colorIndex = s != null && typeof s === 'string' ? hashCode(s, colors.length) : undefined;
  return colors[colorIndex ?? 0];
};

export const isNotEmpty = (s: string | null | undefined): s is string => s != null && s.length > 0;

export const extractSizeFromClassName = (className?: string) => {
  const value = parseFloat(className?.match(/size-(\d+)/)?.[1] || '');
  return isNaN(value) ? undefined : Math.round(value * 4);
};

export function buttonHeightAndBorder(size?: ButtonProps['size']) {
  return {
    'h-11': size == 'md',
    'h-9': size == null,
    'h-7': size === 'sm',
    'h-5': size === 'xs',
    ...buttonTextAndBorder(size),
  };
}

export function buttonTextAndBorder(size?: ButtonProps['size']) {
  return {
    'min-h-11 text-md rounded-lg': size == 'md',
    'min-h-9 text-base rounded-lg': size == null,
    'min-h-7 text-xs rounded-md': size === 'sm',
    'min-h-5 text-2xs rounded': size === 'xs',
  };
}

export function buttonHPadding(size?: ButtonProps['size']) {
  return {
    'first:pl-3.5 last:pr-3.5': size == 'md',
    'first:pl-3 last:pr-3': size == null,
    'first:pl-2 last:pr-2': size == 'sm',
    'first:pl-1.5 last:pr-1.5': size == 'xs',
  };
}

export function buttonCommonClassName<ID extends string = string>({
  size,
  disabled,
  type,
  active,
  text,
  className,
}: ButtonProps<ID>) {
  return cn(
    'inline-flex overflow-hidden border font-sans transition-colors justify-center font-medium',
    buttonHeightAndBorder(size),
    { 'cursor-pointer': !disabled, 'cursor-not-allowed opacity-20': disabled },

    (type == 'button' || type == null) && {
      'text-slate-600 dark:text-slate-200': text && !active,
      'hover:bg-slate-200 active:bg-slate-300': text && !active && !disabled,

      'border-slate-200 dark:border-slate-700': !text,
      'bg-slate-50 text-slate-600 dark:bg-slate-800 dark:text-slate-200': !text,
      'bg-slate-300 text-slate-600 dark:text-slate-200 dark:bg-slate-500': active,
      'hover:bg-slate-100 active:bg-slate-200': !text && !disabled,
      'hover:bg-slate-400 active:bg-slate-500 hover:text-slate-200': active && !disabled,
      'dark:hover:bg-slate-700 dark:active:bg-slate-600': !disabled,
    },
    type == 'submit' && {
      'text-haiti-600 dark:text-haiti-200': text,
      'hover:bg-haiti-200 hover:text-haiti-600 active:bg-haiti-300': text && !disabled,

      'border-haiti-700 dark:border-haiti-500': !text,
      'bg-haiti-600 text-haiti-50 dark:bg-haiti-700 dark:text-haiti-50': !text || active,
      'hover:bg-haiti-700 active:bg-haiti-800': !text && !disabled,
      'hover:bg-haiti-700 hover:text-haiti-100 active:bg-haiti-800': active && !disabled,
      'dark:hover:bg-haiti-800 dark:active:bg-haiti-900': !disabled,
    },
    type == 'reset' && {
      'text-fair-pink-600 dark:text-fair-pink-200': text,
      'hover:bg-fair-pink-200 hover:text-fair-pink-100 active:bg-fair-pink-300': text && !disabled,

      'border-fair-pink-700': !text,
      'bg-fair-pink-600 text-fair-pink-50': !text || active,
      'hover:bg-fair-pink-700 active:bg-fair-pink-800': !disabled,
    },
    { 'bg-transparent': text == true && active != true },
    { 'border-transparent': text == true || active == true },
    className,
  );
}
